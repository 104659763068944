<template>
  <div id="Details">
    <div class="Details_box">
      <div
        class="Details_box_pic"
        :style="{ 'background-image': 'url(' + DetailData.weapon_cover + ')' }"
        :class="this.$route.name == 'Lucky_open' ? 'active' : ''"
      >
        <img
          v-show="this.$route.name != 'Activity_open'"
          :src="DetailData.cover"
          alt=""
          class="dao"
        />
        <!-- <img v-show="this.$route.name != 'Activity_open'" :src="DetailData.cover" alt="">
				<img v-show="this.$route.name != 'Activity_open'" :src="DetailData.cover" alt=""> -->
      </div>
      <!-- <p v-show="this.$route.name != 'Activity_open'" class="Details_Price" style='color:#fff'><img src="@/assets/images/public/Gold.png" alt="">{{(DetailData.bean * NumIdx).toFixed(2)}}</p> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "Details",
  props: {
    DetailData: {
      //宝箱数据
      type: Object,
    },
    NumIdx: {
      //开启数量
      type: Number,
    },
  },
  data() {
    return {};
  },
};
</script>

<style scoped lang="scss">
#Details {
  padding: 10px 0 20px 0;
  // margin-top: -15px;
  box-sizing: border-box;

  .Details_box {
    width: 100%;
    height: 200px;
    margin: 0 auto;
    background: url(../../assets/images/open/box_back.png) no-repeat center;
    background-size: contain;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 0 auto;
    .Details_box_pic {
      width: 100%;
      height: 250px;
      margin-right: 5px;
      background-position: center 10px;
      display: flex;
      background-size: contain;
      background-repeat: no-repeat;
      z-index: 9;
      &.active {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      img {
        max-width: 50%;
        vertical-align: middle;
        margin: 0 auto;
        z-index: 9;
      }
      .dao {
        max-height: 60% !important;
        margin-top: 1rem;
      }
    }
    .Details_Price {
      z-index: 9;
      font-size: 16px;
      color: #f1bc60;
      z-index: 9;
      margin-bottom: -1.5rem;
      margin-top: 1rem;
      img {
        width: 20px;
        height: 20px;
        vertical-align: middle;
        margin-bottom: 2px;
      }
    }
  }
}
</style>
