<template>
  <div id="Activity_open">
    <div class="Title">
      <p>{{ DetailData.name }}</p>
    </div>
    <Details
      :DetailData="DetailData"
      :NumIdx="NumIdx"
      v-show="AnimationShow == '0'"
    ></Details>
    <Dynamic
      :DetailList="DetailList"
      :Awardlist="Awardlist"
      :Dynamic_checked="Dynamic_checked"
      :Dynamic_num="Dynamic_num"
      @setAnimation="setAnimation"
      @setEquipment="setEquipment"
      v-show="AnimationShow == '1'"
    ></Dynamic>
    <div class="open_btn" v-show="AnimationShow == '0'">
      <van-field
        center
        clearable
        v-model="cdk_code"
        type="text"
        label="CDK"
        v-show="this.$route.query.type == '4'"
      >
        <!-- <template #button>
          <van-button
            size="small"
            type="info"
            @click="PostOpen"
            style="color: #000"
            >开启箱子</van-button
          >
        </template> -->

      </van-field>
      <div class="open_btns" v-show="AnimationShow == '0'">
        <div @click="xiang = true">
          <img
            src="../../assets/images/q2/openbox/boxgong.png"
            alt=""
            style="width: 50px"
          />
          <p>查看箱内饰品</p>
        </div>
      </div>
      <!-- <van-button
        type="info"
        size="small"
        @click="PostOpen"
        v-show="this.$route.query.type != '4'"
        >开启箱子</van-button
      > -->
      <div class="Animation_switch">
        <div style="display:flex;align-items: center;">
			<p>跳过动画</p>
        	<van-switch v-model="Animation_checked" size="15px" />
		</div>
		
      </div>
	  <div>
			<img src="../../assets/images/q2/openbox/open.png" @click="PostOpen" alt="" style="width:150px">
		</div>
    </div>

    <Equipment
      :Dynamic_num="Dynamic_num"
      :EquipmentItem="EquipmentItem"
      v-show="EquipmentShow == '1'"
      :key="EquipmentShow"
    ></Equipment>
    <Goods :DetailList="DetailList" :id="DetailData.id" :key="timer"></Goods>
    <Show
      v-show="WinningShow"
      :WinningShow="WinningShow"
      @setShow="setShow"
      :Awardlist="Awardlist"
    ></Show>
    <div class="xiangnei" v-show="xiang == true">
      <div class="littlexiang">
        <h2>箱内物品</h2>
		<span  class="closes"
          @click="xiang = false">×</span>
        <!-- <img
          src="../../assets/images/q2/openbox/close.png"
          alt=""
          class="closes"
          @click="xiang = false"
        /> -->
        <!-- <div class="Explosive">
					<p><img src="../../assets/images/open/yellow.png" alt="">:{{yellow.toFixed(2)}}%</p>
					<p><img src="../../assets/images/open/red.png" alt="">:{{red.toFixed(2)}}%</p>
					<p><img src="../../assets/images/open/prople.png" alt="">:{{purple.toFixed(2)}}%</p>
					<p><img src="../../assets/images/open/blue.png" alt="">:{{blue.toFixed(2)}}%</p>
					<p><img src="../../assets/images/open/hui.png" alt="">:{{gray.toFixed(2)}}%</p>
				</div> -->
        <div class="Goods_list">
          <div
            class="Goods_item"
            v-for="(item, index) in DetailList"
            :key="index"
            :class="item.lv | LvGuoLv"
            @click="goodItem(item)"
            v-show="item.lv == 1 && DetailList[0].lv == 6 ? flags : true"
          >
            <div class="probability">
              <span :style="item.name == '金球' ? 'display:none' : ''"
                ><img src="@/assets/images/public/Gold.png" alt="" />{{
                  item.bean
                }}</span
              >
              <!-- <span :style="item.name=='金球'?'display:none':''">
								概率：{{item.odds_percent}}
							</span> -->
            </div>
            <div
              class="Goods_pic"
              :style="{ 'background-image': 'url(' + item.lv_bg_image + ')' }"
            >
              <img :src="item.cover" alt="" />
            </div>
            <p >{{ item.name }}</p>

            <!-- <div class="" style="font-size: 12px;color: #FFF;margin-top:10px;margin-bottom:10px;">{{item.dura_alias}}</div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Details from "@/components/PubOpen/Acdetails.vue";
import Dynamic from "@/components/PubOpen/Dynamic.vue";
import Equipment from "@/components/PubOpen/Equipment.vue";
import Goods from "@/components/PubOpen/Goods.vue";
import Show from "@/components/PubOpen/Show.vue";
import { BoxDetail, WelfareOpen, PersonalInfo } from "@/network/api.js";
import { mapMutations } from "vuex";
export default {
  name: "Open",
  data() {
    return {
      DetailData: {}, //箱子信息
      NumIdx: 1, //开箱数量
      DetailList: [], //箱内物品
      cdk_code: "", //cdk编码
      Animation_checked: false, //动画开关
      WinningShow: false, //奖品遮罩层
      Awardlist: [], //获得奖品数据
      Dynamic_checked: "0", //播放动画('0':不播放,'1':播放)
      Dynamic_num: 0, //奖品数量
      AnimationShow: "0", //动画显示隐藏'0':不显示,'1':显示)
      EquipmentItem: {}, //下方奖品栏子级
      EquipmentShow: "0", //下方奖品栏显示
      timer: null,
      xiang: false,
    };
  },
  created() {
    this.GetBoxDetail();
  },
  filters: {
		LvGuoLv(val){
			if (val == '1') {
				return 'lv1'
			} else if (val == '2') {
				return 'lv2'
			} else if (val == '3') {
				return 'lv3'
			} else if (val == '4') {
				return 'lv4'
			}else if (val == '5') {
				return 'lv5'
			}else if (val == '6') {
				return 'lv6'
			}
		},
		GuoLv(val) {
			if (val == '1') {
				return 'color:#e6ba1c'
			} else if (val == '2') {
				return 'color:#d32ce6'
			} else if (val == '3') {
				return 'color:#834fff'
			} else if (val == '4') {
				return 'color:#4b69ff'
			} else if (val == '5') {
				return 'color:#bbbbbb'
			}else if (val == '6'){
				return 'color:#e6ba1c'
			}
		},
		GuoLvTwo(val){
			if (val == '1') {
				return require('@/assets/images/q2/openbox/1.png')
			} else if (val == '2') {
				return require('@/assets/images/q2/openbox/2.png')
			} else if (val == '3') {
				return require('@/assets/images/q2/openbox/3.png')
			} else if (val == '4') {
				return require('@/assets/images/q2/openbox/4.png')
			} else if (val == '5') {
				return require('@/assets/images/q2/openbox/5.png')
			} else if (val == '6') {
				return require('@/assets/images/Swiper/Lv1.png')
			}
		}
	},
  methods: {
    //宝箱详情
    GetBoxDetail() {
      BoxDetail(this.$route.query.activityid).then((res) => {
        // console.log(res)
        this.DetailData = res.data.data;
        this.DetailList = res.data.data.box_awards;
        // console.log(this.DetailList)
      });
    },
    //开箱
    PostOpen() {
      if (this.$route.query.type == "4") {
        WelfareOpen(this.$route.query.id, this.cdk_code).then((res) => {
          this.timer = new Date().getTime();
          // console.log(res.data.data.awardlist)
          this.Subsidiary(res.data.data.awardlist);
        });
      } else {
        WelfareOpen(this.$route.query.id).then((res) => {
          this.timer = new Date().getTime();
          this.Subsidiary(res.data.data.awardlist);
        });
      }
    },

    //开箱附属
    Subsidiary(list) {
      this.GetPersonalInfo();
      this.Awardlist = list;
      if (this.Animation_checked == true) {
        this.WinningShow = true;
      } else {
        this.AnimationShow = "1";
        this.EquipmentShow = "1";
        // console.log('false')
        setTimeout(() => {
          this.Dynamic_checked = "1";
          this.Dynamic_num = this.Awardlist.length;
        }, 100);
      }
    },

    //个人信息
    GetPersonalInfo() {
      PersonalInfo().then((res) => {
        this.PostUser(res.data.message);
      });
    },

    //接收子级(关闭奖品遮罩层)
    setShow(v) {
      // console.log(v)
      this.WinningShow = v;
      this.EquipmentShow = "0";
    },

    //接收子级(动画结束)
    setAnimation(v) {
      this.WinningShow = v;
      this.AnimationShow = "0";
      this.Dynamic_checked = "0";
    },

    //接收子级(下方奖品栏)
    setEquipment(v) {
      // console.log(v)
      this.EquipmentItem = this.Awardlist[v - 1];
    },

    ...mapMutations(["PostUser"]),
  },

  components: {
    Details,
    Dynamic,
    Equipment,
    Goods,
    Show,
  },
};
</script>

<style  lang="scss">
.xiangnei {
  position: fixed;
  background: rgba($color: #000000, $alpha: 1);
  z-index: 99;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  .littlexiang {
    width: 90%;
    height: 500px;
    margin: 100px auto;
    background: url("../../assets/images/q2/openbox/xiangbac.png") no-repeat
      center;
    background-size: 100% 100%;
    padding: 10px;
    .closes{
			position: absolute;
			width: 30px;
			height: 30px;
			right: 5px;
			top: 100px;
			font-size: 30px;
			color: white
		}
    h2 {
      color: white;
      text-align: center;
      position: relative;
      top: 0;
    }
  }
}

#Activity_open {
  .box_num {
    width: 220px;
    padding: 15px 0;
    margin: -20px auto 0;
    background: rgba($color: #000000, $alpha: 0.2);
    display: flex;
    justify-content: center;
    border-radius: 6px;
    margin-bottom: 10px;

    p {
      width: 30px;
      height: 30px;
      background: #fff;
      color: #000;
      text-align: center;
      line-height: 30px;
      border-radius: 4px;
      margin: 0 5px;

      &.active {
        background: #ffba2e;
        color: #fff;
      }
    }
  }
  .open_btns {
    position: absolute;
    right: 20px;
    top: 55%;
    color: white;
  }
  .open_btn {
    text-align: center;
	position: relative;
    .van-button {
      font-size: 14px;
      padding-left: 20px;
      padding-right: 20px;
    }

    .van-cell {
      background: none;
      margin-top: 10px;

      .van-field__label {
        width: 32px;
        font-size: 14px;
        color: #fff;
      }

      .van-field__control {
        color: #fff;
      }

      .van-button {
        margin-top: 0;
      }
    }

    .van-cell::after {
      background: #fff;
    }

    .Animation_switch {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-right: 10px;
      margin-top: 10px;
      box-sizing: border-box;
      font-size: 16px;
      color: #fff;
		display: flex;
		flex-wrap: wrap;
		
		
      p {
        margin-right: 5px;
      }
    }
  }
}
.Goods_list {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  border-radius: 0 4px 4px 4px;
  justify-content: space-between;
  overflow: scroll;
  height: 440px;
  align-content: flex-start;
  &::after {
    display: block;
    content: "";
    width: 32.5%;
  }
  .Goods_item {
    &.lv1 {
      background-size: 100% 100%;
      border-radius: 5px;
    //   border: 1px solid rgba(233, 212, 90, 0.358);
      background: linear-gradient(
        to top,
        rgba(255, 0, 0, 0),
        rgb(139, 111, 18)
      );
      background: url("../../assets/images/q2/openbox/1.png") no-repeat center;
      background-size: 100% 100%;
    }
    &.lv2 {
      background-size: 100% 100%;
      border-radius: 5px;
      // border: 1px solid rgb(210, 73, 73);
      background: linear-gradient(to top, rgba(0, 0, 0, 0), rgb(133, 46, 46));
      background: url("../../assets/images/q2/openbox/2.png") no-repeat center;
      background-size: 100% 100%;
    }
    &.lv3 {
      background-size: 100% 100%;
      border-radius: 5px;
    //   border: 1px solid rgb(203, 70, 203);
      background: linear-gradient(to top, rgba(255, 0, 0, 0), rgb(57, 44, 83));
      background: url("../../assets/images/q2/openbox/3.png") no-repeat center;
      background-size: 100% 100%;
    }
    &.lv4 {
      background-size: 100% 100%;
      border-radius: 5px;
    //   border: 1px solid rgb(86, 86, 214);
      background: linear-gradient(to top, rgba(255, 0, 0, 0), rgb(57, 79, 102));
      background: url("../../assets/images/q2/openbox/4.png") no-repeat center;
      background-size: 100% 100%;
    }
    &.lv5 {
      background-size: 100% 100%;
      border-radius: 5px;
    //   border: 1px solid rgb(96, 96, 113);
      background: linear-gradient(
        to top,
        rgba(81, 72, 72, 0),
        rgb(87, 92, 104)
      );
      background: url("../../assets/images/q2/openbox/5.png") no-repeat center;
      background-size: 100% 100%;
    }
    &.lv6 {
      background-size: 100% 100%;
      border-radius: 5px;
    //   border: 1px solid rgba(233, 212, 90, 0.358);
      background: linear-gradient(
        to top,
        rgba(255, 0, 0, 0),
        rgb(139, 111, 18)
      );
      background: url("../../assets/images/q2/openbox/1.png") no-repeat center;
      background-size: 100% 100%;
    }
    width: 46%;
    // background: rgba($color: #000000, $alpha: .2);
    background-size: 100% 100% !important;
    // background-size: cover;
    height: 130px;
    border-radius: 4px;
    text-align: center;
    margin: 5px;
    background-position: -20px -20px;
    padding-top: 10px;
    .probability {
      justify-content: space-between;
      display: flex;
      width: 100%;
      height: 30px;
      line-height: 25px;
      font-size: 10px;
      color: #fff;
      text-align: left;
      padding: 0 20px;
      box-sizing: border-box;
      & span:nth-child(1) {
        color: #ecb105;
      }
      & span:nth-child(2) {
        color: #dbdce8;
      }
    }
    .Goods_pic {
      width: 80%;
      height: 70px;
      margin: 0 auto;
      display: flex;
      justify-content: center;
      align-items: center;
      background-image: url(http://csgo.api.gameskins.cn/uploads/images/baeef9d5696b72118858f87ff955bee7.png);
      background-position: center;
      background-size: 80%;
      background-repeat: no-repeat;
      img {
        max-width: 70%;
        vertical-align: middle;
      }
    }
    p {
      font-size: 12px;
      color: #fff;
      width: 80%;
      text-align: center;
      margin: 0 auto;
      overflow: hidden;
      /* 第二步：让文本不会换行， 在同一行继续 */
      white-space: nowrap;
      /* 第三步：用省略号来代表未显示完的文本 */
      text-overflow: ellipsis;
    }
    span {
      display: flex;
      justify-content: center;
      align-items: center;
      color: #f1bc60;
      margin: 5px 0;
      img {
        width: 16px;
      }
    }
  }
}
.Explosive {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background: url(../../assets/images/open/Explosive.png) no-repeat center;
  background-size: 100% 100%;
  height: 30px;
  color: white;
  width: 98%;
  margin: 5px auto;
  img {
    width: 10px;
  }
}
.Goods_box{
	margin-top: 0 !important;
}
</style>
